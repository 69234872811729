<template>
  <div id="footer" v-if="!this.$route.path.startsWith('/admin')">
    <div id="footer-container">

      <h2>Flin's On Demand</h2>

      <div id="twin-columns">
        <div id="left-column">
          <!-- <a @click="this.$router.push('/privacy')">Privacy</a>
          <a @click="this.$router.push('/terms')">Terms</a> -->
          <a href="https://flinsfitness.co.uk">Main Website</a>
          <a href="mailto:fran@flinsfitness.co.uk">Contact</a>
        </div>

        <div id="right-column">
          <h2>FOLLOW US</h2>
          <span>
            <a class="fab fa-instagram" href="https://www.instagram.com/flins_fitness/"></a>
            <a class="fab fa-facebook" href='https://www.facebook.com/flinsfitness'></a>
            <a class="fab fa-youtube" href='https://www.youtube.com/user/flinsfitnessclasses'></a>
            <a class="fab fa-twitter" href="https://twitter.com/flinsfitness"></a>
            <a class="fab fa-linkedin" href="https://www.linkedin.com/in/fran-flin-3158b1172/"></a>
          </span>
        </div>
      </div>

      <div id="bottom">
        <p>&copy; 2024 Flin's Fitness</p>
        <p>Powered by <a href="https://bookedsolid.co.uk">BookedSolid</a></p>
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">
@import "/src/assets/styles/variables.scss";

#footer {
  width: 100%;
  background: #2b2a2a;
  color: white;
  padding: 20px 0;
  text-align: left;

  #footer-container {
    width: 80%;
    margin-left: 10%;

    #twin-columns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;

      #left-column {
        display: flex;
        justify-content: space-between;
        align-items: left;
        flex-direction: column;
        width: 50%;
        max-width: 600px;

        a {
          color: white;
          text-decoration: underline;
          font-size: 1.2rem;
          transition: all 0.1s ease-in-out;
          line-height: 1.4;
          cursor: pointer;

          &:hover {
            color: $colourP;
          }
        }
      }

      #right-column {
        display: flex;
        justify-content: space-between;
        align-items: left;
        flex-direction: column;
        width: 50%;
        max-width: 600px;

        h2 {
          font-size: 1.2rem;
          margin-right: 20px;
        }

        span {
          font-size: 1.5rem;

          a {
            padding: 0 20px;
            transition: all 0.1s ease-in-out;
            color: inherit;
            text-decoration: none;
            cursor: pointer;

            &:hover {
              color: $colourP;
            }
          }
        }
      }
    }

    #bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 40px;

      a {
        color: #2596be;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  #footer {
    #footer-container {
      #twin-columns {
        flex-direction: column;

        #left-column {
          width: 100%;
          margin-bottom: 20px;
        }

        #right-column {
          width: 100%;
        }
      }
    }
  }
}
</style>