<template>
  <nav v-if="!admin && mobileNav">
    <div id="mobileNavBar">
      <img @click="() => { this.$router.push('/'); this.showNav = false }" src="@/assets/logo-wide.png" alt="logo" />
      <i class="fa fa-bars" id="openNav" :class="!showNav && 'active'" @click="showNav = !showNav"></i>
      <i class="fa fa-times" id="closeNav" :class="showNav && 'active'" @click="showNav = !showNav"></i>
    </div>
    <div class="mobileNavDropdown" :class="showNav && 'dropdownOpen'">
      <router-link @click="showNav = false" to="/">Home</router-link>
      <router-link @click="showNav = false" to="/favourites">Favourites</router-link>
      <router-link @click="showNav = false" to="/history">History</router-link>
      <router-link @click="showNav = false" class="join" v-if="!this.authed" to="/login">Register</router-link>
      <router-link @click="showNav = false" v-if="this.authed" to="/profile">My Account</router-link>
      <!-- <a v-if="this.email == 'oliver@decentapps.co.uk'" href="/admin">Admin</a> -->
    </div>
  </nav>
  <nav v-if="!admin && nav">
    <img id="floatImg" @click="this.$router.push('/')" src="@/assets/logo-wide.png" alt="logo" />
    <router-link to="/">Home</router-link>
    <router-link to="/favourites">Favourites</router-link>
    <router-link to="/history">History</router-link>
    <router-link class="join" v-if="!this.authed" to="/login">Register</router-link>
    <router-link v-if="this.authed" to="/profile">My Account</router-link>
    <!-- <a v-if="this.email == 'oliver@decentapps.co.uk'" href="/admin">Admin</a> -->
  </nav>
  <nav v-if="admin && nav" id="adminDesktopNav">
    <router-link to="/admin"><img src="@/assets/od-logo.png" alt="logo" /></router-link>
    <router-link to="/admin/prices"><i class="fa fa-pound-sign"></i></router-link>
    <router-link to="/admin/analytics"><i class="fa fa-chart-line"></i></router-link>
    <router-link to="/admin/users"><i class="fa fa-users"></i></router-link>
    <a v-if="authed" href="/" id="logout"><i class="fa-solid fa-right-from-bracket"></i></a>
  </nav>
  <nav v-if="admin && mobileNav" id="adminMobileNav">
    <div id="mobileAdminNavBar">
      <i class="fa fa-caret-down" id="openNav" :class="!showNav && 'active'" @click="showNav = !showNav"></i>
      <i class="fa fa-caret-up" id="closeNav" :class="showNav && 'active'" @click="showNav = !showNav"></i>
    </div>
    <div id="mobileAdminNavDropdown" :class="showNav && 'dropdownOpen'">
      <router-link @click="showNav = false" to="/admin"><img src="@/assets/logo-wide.png" alt="logo" /> Videos</router-link>
      <router-link @click="showNav = false" to="/admin/prices"><i class="fa fa-pound-sign"></i> Prices</router-link>
      <router-link @click="showNav = false" to="/admin/analytics"><i class="fa fa-chart-line"></i> Analytics</router-link>
      <router-link @click="showNav = false" to="/admin/users"><i class="fa fa-users"></i> Users</router-link>
    </div>
  </nav>
  <router-view />

  <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    Footer,
},
  data() {
    return {
      authed: false,
      admin: false,
      nav: true,
      email: localStorage.email,
      mobileNav: false,
      showNav: false,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem('userSession');
      localStorage.removeItem('operator');
      localStorage.removeItem('admin');
      this.$router.push('/login');
      this.authed = false;
      this.operator = false;
      this.admin = false;
    },
    setSize() {
      if (window.innerWidth < 600) {
        this.nav = false;
        this.mobileNav = true;
      }
    }
  },
  mounted() {
    if (window.location.pathname.split('/')[1] == 'admin') {
      this.admin = true;
      document.getElementsByTagName('body')[0].classList.add('admin');
      if (localStorage.getItem('adminSession')) this.authed = true;
      else this.$router.push('/admin/login');
    } else {
      document.getElementsByTagName('body')[0].classList.add('main');
      if (localStorage.getItem('userSession')) this.authed = true;
    }

    this.setSize();
    window.addEventListener('resize', this.setSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
}
</script>

<style lang="scss">
@import "/src/assets/styles/variables.scss";

body.main {
  margin: 0;
  padding: 0;
}

.main {
  #app {
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: $text;
    letter-spacing: 0.8px;

    nav {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100px;
      background: #2b2a2a;
      display: flex;
      align-items: center;
      justify-content: right;
      padding: 0 10%;
      box-sizing: border-box;
      font-size: 1.1rem;
      z-index: 10;

      img {
        position: fixed;
        top: 25px;
        left: 10%;
        height: 50px;
        cursor: pointer;
      }

      a {
        text-decoration: none;
        color: white;
        font-weight: bold;
        margin: 0 20px;
        text-shadow: 1px 1px 12px #000000;

        &.router-link-exact-active {
          text-decoration: underline;
        }

        &.book {
          border: 3px solid white;
          padding: 10px 20px;
          box-shadow: 1px 1px 12px #000000b9;

          &:hover {
            background-color: white;
            color: #2b2627;
            text-shadow: none;
          }
        }

        &.join {
          font-size: 1rem;
        }
      }
    }
  }
}

.admin {
  #app {
    position: fixed;
    top: 0;
    left: 90px;
    width: calc(100% - 90px);
    height: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: small;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: white;
    background-color: #272a2c;

    h1 {
      color: $colourT;
    }

    button, select, option, input {
      font-family: inherit;
      font-size: inherit;
    }
  }

  #adminDesktopNav {
    position: fixed;
    top: 0;
    left: 0;
    width: 50px;
    height: 100%;
    padding: 20px;
    background-color: #1b1e1f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
      font-weight: bold;
      text-decoration: none;
      color: white;
      display: block;
      width: 70px;
      height: 70px;
      margin: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      transition: background-color 0.1s ease-in-out;
      font-size: 25px;

      &.router-link-exact-active {
        background-color: #58595B;
      }

      img {
        width: 30px;
      }
    }

    #logout {
      cursor: pointer;
    }
  }

  @media (max-width: 600px) {
    #app {
      left: 0;
      width: 100%;
    }
  }
}

.fa-spinner {
  animation: spin 2s linear infinite;
}

// Loader
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 600px) {
  #app {
    left: 0;
    width: 100%;
  }

  #openNav,
  #closeNav {
    position: absolute;
    right: 40px;
    opacity: 0;
    rotate: 90deg;
    transition: 0.2s;
    cursor: pointer;
  }

  #closeNav {
    rotate: -90deg;
  }

  #openNav.active,
  #closeNav.active {
    z-index: 3;
    opacity: 1;
    rotate: 0deg;
  }

  #mobileNavBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10%;
    box-sizing: border-box;
    font-size: 1.1rem;
    z-index: 10;

    img {
      height: 50px;
    }

    i {
      font-size: 1.8rem;
      padding: 10px;
      color: white;
      cursor: pointer;
    }
  }

  .mobileNavDropdown {
    height: 0px;
    width: 100vw;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 100px;
    transition: 0.2s;
    text-align: left;
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    a {
      margin-top: 40px !important;
      margin-left: 40px !important;
      font-size: 1.5rem;
      width: fit-content;
    }
  }

  .dropdownOpen {
    height: 100vh;
    background-image: linear-gradient(to bottom, #2b2a2a, #2b2a2a, #00000000);
  }

  .admin {
    #mobileAdminNavBar {
      position: fixed;
      top: 10px;
      left: 100px;
      z-index: 100;
    }

    #mobileAdminNavDropdown {
      display: none;
      position: fixed;
      top: 50px;
      left: 0px;
      width: 100vw;
      height: 500px;
      background-color: #000000;
      justify-content: space-evenly;
      align-items: flex-start;
      z-index: 99;

      &.dropdownOpen {
        display: flex;
        flex-direction: column;
        padding: 20px 40px;
        box-sizing: border-box;
      }

      &::before {
        content: "";
        position: absolute;
        top: -49px;
        left: 0;
        width: 100%;
        height: 50px;
        background-color: black;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -49px;
        left: 0;
        width: 100%;
        height: 50px;
        background-image: linear-gradient(to bottom, #000000, #000000, #00000000);
      }

      a {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        margin-bottom: 30px;
        color: rgba(255, 255, 255, 0.723);
      }

      img {
        width: 50px;
        margin-right: 20px;
      }

      i {
        font-size: 2rem;
        width: 50px;
        margin-right: 20px;
        color: white;
      }
    }

    #openNav,
    #closeNav {
      padding: 20px;
      opacity: 0;
      rotate: 90deg;
      transition: 0.2s;
      cursor: pointer;
    }

    #closeNav {
      rotate: -90deg;
    }

    #openNav.active,
    #closeNav.active {
      z-index: 3;
      opacity: 1;
      rotate: 0deg;
    }
  }
}

body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $text;
  letter-spacing: 0.8px;

  section {
    width: calc(100%);
    box-sizing: border-box;
    max-width: 1200px;
    margin: 50px auto;
    text-align: left;

    .video-section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      padding: 0 20px;

      h2 {
        color: $colourS;
        font-weight: 600;
      }

      a {
        color: $colourS;
        text-decoration: none;
        display: flex;
        align-items: center;
        cursor: pointer;

        i {
          font-size: 1.2em;
          margin-left: 5px;
        }
      }
    }

    .videoList {
      display: flex;
      width: 100%;
      overflow-x: auto;
      padding: 0 20px;
      box-sizing: border-box;
      // padding-bottom: 10px;

      .video {
        width: 70vw;
        max-width: 400px;
        margin-right: 40px;
        margin-bottom: 40px;

        img {
          width: 70vw;
          max-width: 400px;
          object-fit: cover;
          height: 39.376vw;
          max-height: 225px;
          border-radius: 10px;
        }

        h3 {
          color: $colourP;
        }

        .description {
          font-size: 0.9em;
          // min-height: 40px;
        }

        .info {
          display: flex;
          justify-content: space-between;
          font-size: 0.9em;
          opacity: 0.8;
        }

        .tags {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          i {
            margin-right: 5px;
            font-size: 0.8em;
            background-color: $colourS;
            opacity: 0.8;
            padding: 5px;
            border-radius: 5px;
          }
        }
      }

      &.open {
        height: auto;
        max-height: 100vh;
        flex-wrap: wrap;
        justify-content: space-evenly;
        overflow-y: auto;

        .video {
          margin-bottom: 80px;
          width: 80vw;

          img {
            width: 90vw;
            height: 50vw;
          }
        }

        &.no-scroll {
          max-height: unset;
        }
      }

      &::-webkit-scrollbar {
        width: 12px;
        height: 0px;
        border-radius: 5px;
        z-index: 10;
        background-color: #f2f2f2;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #c5cece;
      }
    }

    &.feature {
      .videoList {
        .video {
          width: 80vw;
          max-width: 500px;

          img {
            width: 80vw;
            max-width: 500px;
            height: 45vw;
            max-height: 300px;
          }
        }
      }
    }
  }
}
</style>
