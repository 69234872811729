import axios from "axios";
const url = "https://api.bookedsolid.co.uk";
//  const url = 'http://localhost:5002';
 const client_id = '65db4175b16dd6ba0852b99b';

/***********  User functions ***********/

// Register a user
async function register(name, email, password, mobile, referral_source, parq) {
  const data = {
    name,
    email,
    password,
    // address,
    // postcode,
    mobile,
    // emergency_contact,
    referral_source,
    parq,
    client: client_id
  };
  // send request to server
  const response = await axios.post(`${url}/user/register`, data);
  if (response.status == 201 || response.status == 200) {
    // if successful, add session to localStorage
    localStorage.userSession = response.data.token;
    sessionStorage.userSession = response.data.token;
    localStorage.name = response.data.userDetails.name;
    localStorage.email = response.data.userDetails.email;
    localStorage.newUser = 'true';
    window.location.href = '/';
    return response;
  } else return response.data.message;
}

// Login a user
async function login(email, password, next) {
  const data = {
    email,
    password,
    client: client_id
  };
  // send request to server
  const response = await axios.post(`${url}/user/login`, data)
    .catch((err) => { 
      return alert(err?.response?.data?.message || 'Unexpected error.'); 
    });
  if (response?.status == 200) {
    // if successful, add session to localStorage
    localStorage.userSession = response.data.token;
    sessionStorage.userSession = response.data.token;
    localStorage.name = response.data.name;
    localStorage.email = response.data.email;
    console.log(response);
    window.location.href = next ? `/${next}` : '/';
    return;
  }
}

// Get user's profile
async function getProfile() {
  // send request to server
  const authToken = localStorage.userSession;
  const response = await axios.get(`${url}/user`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`
    }
  }).catch((err) => {
    if (err.response.status == 401) window.location.href = '/login';
    return false;
  });
  // return profile if successful
  console.log(response.data);
  if (response && response.status == 200) {
    return response.data.user;
  } else {
    return false;
  }
}

async function updateProfile(updates) {
  // send request to server
  const authToken = localStorage.userSession;
  const response = await axios.put(`${url}/user/update`, { updates }, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`
    }
  });
  // return profile if successful
  console.log(response);
  if (response && response.status == 200) {
    return response.data.updatedProfile;
  } else {
    alert("Unable to update profile. Please login and retry");
    return false;
  }
}

async function deleteAccount() {
  // send request to server
  const authToken = localStorage.userSession;
  const response = await axios.delete(`${url}/user`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`
    }
  });
  // clear localstorage and return to login if successful
  console.log(response);
  if (response && response.status == 200) {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/login";
  } else {
    alert(response?.data?.message || 'Unable to delete account. Please try again later or contact support.');
    return false;
  }
}

async function getBookings() {
  // send request to server
  const authToken = localStorage.userSession;
  const response = await axios.get(`${url}/user/bookings`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`
    }
  });
  // return bookings if successful
  console.log(response);
  if (response && response.status == 200) {
    return response.data.bookings;
  } else {
    alert("Unable to get bookings. Please login and retry");
    return false;
  }
}

// Request the resetting of a password
async function forgotPassword(email) {
  if (!email) return alert('Please enter your email');
  else {
    const data = {
      email,
      client: client_id
    };
    // send request to server
    const response = await axios.post(`${url}/user/forgot_password`, data).catch((err) => {
      alert(err?.response?.data?.message || 'Unexpected error.');
      return;
    });
    console.log(response);
    if (response && response.status == 200) {
      return alert('Please check your email for instructions');
    }
  }
}

// Reset the password
async function resetPassword(token, new_password, confirm_password) {
  if (!new_password || !confirm_password) return alert('Passwords do not match');
  const data = {
    new_password,
    confirm_password,
    client: client_id
  };
  const response = await axios.post(`${url}/user/reset_password/${token}`, data)
    .catch((err) => {
      alert(err?.response?.data?.message || 'Error updating password. Please try again later.');
      return false;
    });
  console.log(response);
  if (response?.data?.success === true) return true;
  else {
    alert('Error updating password. Please try again later.');
    return false;
  }
}

/***********  Bookings functions ***********/

// Get upcoming schedule
async function getAvailability(site, week) {
  const response = await axios.get(`${url}/booking/availability?site=${site}&week=${week}`);
  console.log(response);
  return response.data.availability;
}

// Book (covers all scenarios)
async function bookClass(data) {
  const authToken = localStorage.userSession;
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${authToken}`
    },
  });
  const response = await instance.post(`/booking`, data);
  console.log(response);
  if (response.data.success === false) alert(response.data.message);
}

// Cancel a booking
async function cancelClass(booking_id) {
  const authToken = localStorage.userSession;
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${authToken}`
    },
  });
  const response = await instance.put(`/booking/cancel/${booking_id}`);
  console.log(response);
  alert(response.data.message);
  if (response?.data?.success === true) return true;
  else return false;
}

// Join waiting list for a bookable
async function joinWaitingList(data) {
    const authToken = localStorage.userSession;
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${authToken}`
    },
  });
  const response = await instance.post(`/booking/waiting-list`, data);
  console.log(response);
  if (response?.data?.success === true) return true;
  else return false;
}

/***********  Payment functions ***********/

async function createCheckout(site_id, price_ids, selection) {
  const data = {
    site_id,
    price_ids,
    selection,
  };
  const authToken = localStorage.userSession;
  const instance = axios.create({
    baseURL: url,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${authToken}`
    },
  });
  const response = await instance.post('/stripe/pay', data);
  console.log(response);
  if (response.status == 200) {
    window.location.href = response.data.url;
  }
}

async function findOffer(site_id, offer_id) {
  const response = await axios.get(`${url}/stripe/offer/${site_id}/${offer_id}`);
  console.log(response);
  if (response?.data?.success === true) {
    return response.data.product;
  } else return false;
}

async function redeemOffer(email, site_id, offer_id) {
  const response = await axios.post(`${url}/stripe/offer`, { email, site_id, offer_id, client: client_id });
  if (response?.data?.success === true) {
    window.location.href = response.data.url;
  } else if (response?.data?.message) alert(response.data.message);
  else alert('This email address has not be registered with us.');
}

// Get all available packages
async function getPrices(site_id) {
  const authToken = localStorage.userSession;
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${authToken}`
    },
  });
  const response = await instance.get(`/stripe/packages/${site_id}`);
  console.log(response);
  if (response?.data?.success === true) return response?.data?.packages;
}

// cancel an active subscription
async function cancelSubscription(credit_id) {
  const authToken = localStorage.userSession;
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${authToken}`
    },
  });
  const response = await instance.put(`/stripe/subscription`, {
    client: client_id,
    credit_id
  });
  console.log(response);
  if (response?.data?.success === true) return true;
  else return false;
}

/***********  General functions ***********/

// Submit a contact form
async function sendContact(name, email, message) {
  const data = {
    name,
    email,
    message,
    client: client_id
  };
  // send request to server
  const response = await axios.post(`${url}/homepage/contact`, data)
    .catch(() => {
      return false;
    });
  console.log(response);
  if (response.status == 200) {
    return true;
  } else return false;
}

/***********  On Demand Video functions ***********/

// Get all videos for a client
async function getVideos(position, limit) {
  const authToken = localStorage.userSession;
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${authToken}`
    },
  });
  const response = await instance.get(`/on-demand/videos/${client_id}?position=${position}&limit=${limit}`);
  console.log(response);
  if (response.data.success === false) alert(response.data.message);
  else return response.data.videos;
}

// Search videos
async function searchVideos(query) {
  const authToken = localStorage.userSession;
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${authToken}`
    },
  });
  const response = await instance.get(`/on-demand/search/${client_id}?query=${query}`);
  console.log(response);
  if (response.data.success === false) alert(response.data.message);
  else return response.data.videos;
}

// Get a video by ID
async function getVideo(id) {
  const authToken = localStorage.userSession;
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${authToken}`
    },
  });
  const response = await instance.get(`/on-demand/video/${id}`);
  console.log(response);
  if (response.data.success === false) alert(response.data.message);
  else return response.data.video;
}


// Get list of user's favourite videos
async function getFavouriteVideos() {
  const authToken = localStorage.userSession;
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${authToken}`
    },
  });
  const response = await instance.get(`/on-demand/favourites`).catch((err) => {
    if (err.response.status == 401) window.location.href = '/login';
    return false;
  });
  console.log(response);
  if (response.data.success === false) alert(response.data.message);
  else return response.data.videos;
}

// Add a video to favourites
async function addFavouriteVideo(id) {
  const authToken = localStorage.userSession;
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${authToken}`
    },
  });
  const data = {
    videoId: id
  };
  const response = await instance.post(`/on-demand/favourites`, data).catch((err) => {
    if (err.response.status == 401) window.location.href = '/login';
    return false;
  });
  console.log(response);
  if (response.data.success === false) {
    alert(response.data.message);
    return false;
  } else return true;
}

// Get list of user's watch history
async function getWatched() {
  const authToken = localStorage.userSession;
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${authToken}`
    },
  });
  const response = await instance.get(`/on-demand/watched`).catch((err) => {
    if (err.response.status == 401) window.location.href = '/login';
    return false;
  });
  console.log(response);
  if (response.data.success === false) alert(response.data.message);
  else return response.data.videos;
}

// Add a video to watched
async function addWatched(id) {
  const authToken = localStorage.userSession;
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${authToken}`
    },
  });
  const data = {
    videoId: id
  };
  const response = await instance.post(`/on-demand/watched`, data).catch((err) => {
    if (err.response.status == 401) window.location.href = '/login';
    return false;
  });
  console.log(response);
  if (response.data.success === false) {
    alert(response.data.message);
    return false;
  } else return true;
}

// Add a comment to a video
async function addComment(id, comment) {
  const authToken = localStorage.userSession;
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${authToken}`
    },
  })
  const data = {
    videoId: id,
    comment
  };
  const response = await instance.post(`/on-demand/comment`, data).catch((err) => {
    if (err.response.status == 401) window.location.href = '/login';
    return false;
  });
  console.log(response);
  if (response.data.success === false) {
    alert(response.data.message);
    return false;
  } else return response.data.video;
}

// Get a video collection
async function getCollection(id) {
  const response = await axios.get(`${url}/on-demand/collection/${client_id}/${id}`);
  console.log(response);
  return response?.data?.collection;
}

// Get all video collections
async function getCollections() {
  const response = await axios.get(`${url}/on-demand/collections/${client_id}`);
  console.log(response);
  return response?.data?.collections
}

export {
  login,
  register,
  getProfile,
  updateProfile,
  deleteAccount,
  getBookings,
  forgotPassword,
  resetPassword,
  getAvailability,
  bookClass,
  cancelClass,
  joinWaitingList,
  createCheckout,
  findOffer,
  redeemOffer,
  cancelSubscription,
  sendContact,
  getPrices,
  getVideos,
  searchVideos,
  getVideo,
  getFavouriteVideos,
  addFavouriteVideo,
  getWatched,
  addWatched,
  addComment,
  getCollection,
  getCollections
};